import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import PhoneIcon from "@mui/icons-material/Phone";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CommentIcon from "@mui/icons-material/Comment";
import QuizIcon from '@mui/icons-material/Quiz';
import PanToolIcon from "@mui/icons-material/PanTool";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import EmailIcon from "@mui/icons-material/Email";
import CheckIcon from "@mui/icons-material/Check";
import StarIcon from "@mui/icons-material/Star";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ContentCopy from "@mui/icons-material/ContentCopy";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en'


import { useMediaQuery } from "@mui/material";

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

const kinds = [
  "sourced",
  "applied",
  "phone-screen",
  "interview",
  "offer",
  "hired",
  "shortlisted",
];

const icons = {
  sourced: (
    <SearchIcon
      sx={{ position: "absolute", left: 0, top: "4px" }}
      fontSize="large"
      color="primary"
    />
  ),
  applied: (
    <EditIcon
      sx={{ position: "absolute", left: 0, top: "4px" }}
      fontSize="large"
      color="primary"
    />
  ),
  "phone-screen": (
    <PhoneIcon
      sx={{ position: "absolute", left: 0, top: "4px" }}
      fontSize="large"
      color="primary"
    />
  ),
  interview: (
    <GroupsIcon
      sx={{ position: "absolute", left: 0, top: "4px" }}
      fontSize="large"
      color="primary"
    />
  ),
  offer: (
    <LocalOfferIcon
      sx={{ position: "absolute", left: 0, top: "4px" }}
      fontSize="large"
      color="primary"
    />
  ),
  hired: (
    <CheckIcon
      sx={{ position: "absolute", left: 0, top: "4px" }}
      fontSize="large"
      color="primary"
    />
  ),
  shortlisted: (
    <StarIcon
      sx={{ position: "absolute", left: 0, top: "4px" }}
      fontSize="large"
      color="primary"
    />
  ),
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

function MemberKindActivity({ item, kind }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  const stringedKind = kind.replace("-", " ");
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? "" : icons[kind]}
        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar {...stringAvatar(item.member.name)} />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box component="span">Moved to {stringedKind} by</Box>
              <Box component="span" sx={{ fontWeight: "bold", paddingLeft: "5px", paddingRight: "5px" }}>
                {item.member.name}
              </Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function MemberResurfacedActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <ContentCopy
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}
        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar {...stringAvatar(item.member.name)} />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box component="span" sx={{ fontWeight: "bold" }}>
                {item.member.name} 
              </Box>
              <Box component="span">  resurfaced </Box>
              <Box component="span" sx={{ fontWeight: "bold", paddingRight: "5px" }}>
                {candidate.name}
              </Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function AppliedActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <EditIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}

        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar
              src={
                candidate.image_url ??
                "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              }
            />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box component="span" sx={{ fontWeight: "bold" }}>
                {candidate.name}
              </Box>
              <Box component="span">{" "}applied{" "}</Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
      </Box>
    </Box>
  );
}

function MemberMessageActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <EmailIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}

        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar {...stringAvatar(item.member.name)} />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box component="span" sx={{ fontWeight: "bold" }}>
                {item.member.name}
              </Box>
              <Box component="span">{" "}sent an Email{" "}</Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          dangerouslySetInnerHTML={{
            __html: item.body,
          }}
        ></Box>
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function CandidateMessageActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <EmailIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}

        <Grid container>
          <Grid item xs={2} sm={1}>
            {candidate.image_url ? <img
              style={{ borderRadius: "50px" }}
              width="50"
              height="50"
              src={candidate.image_url ?? "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"}
            /> : <Avatar {...stringAvatar(candidate.name)} />}
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box component="span" sx={{ fontWeight: "bold" }}>
                {candidate.name}
              </Box>
              <Box component="span">{" "}sent an Email{" "}</Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          dangerouslySetInnerHTML={{
            __html: item.body,
          }}
        ></Box>
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function VideoInterviewRequestedActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <VideoCameraFrontIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}
        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar {...stringAvatar(item.member.name)} />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box component="span" sx={{ fontWeight: "bold" }}>
                {item.member.name}
              </Box>
              <Box component="span">{" "}sent a video interview invatation{" "}</Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function VideoInterviewCompletedActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <VideoCameraFrontIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}

        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar
              src={
                candidate.image_url ??
                "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              }
            />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box component="span" sx={{ fontWeight: "bold", paddingRight: "5px" }}>
                {candidate.name}
              </Box>
              <Box component="span">{" "}completed a video interview{" "}</Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function AssessmentRequestedActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <AssessmentIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}
        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar {...stringAvatar(item.member.name)} />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box component="span" sx={{ fontWeight: "bold" }}>
                {item.member.name}
              </Box>
              <Box component="span">
                {" "}sent an assessment invatation{" "}
              </Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function AssessmentCompletedActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <AssessmentIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}

        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar
              src={
                candidate.image_url ??
                "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              }
            />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box
                component="span"
                sx={{ fontWeight: "bold", paddingRight: "5px" }}
              >
                {candidate.name}
              </Box>
              <Box component="span" sx={{ paddingRight: "5px" }}>
                completed assessment
              </Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function CommentActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <CommentIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}

        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar {...stringAvatar(item.member.name)} />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box
                component="span"
                sx={{
                  fontWeight: "bold",
                  paddingRight: "5px",
                }}
              >
                {item.member.name}
              </Box>
              <Box component="span" sx={{paddingRight: "5px"}}>added a comment</Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Box component="span">{item.body}</Box>
        <br />
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function RatingActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <QuizIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}

        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar {...stringAvatar(item.member.name)} />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box
                component="span"
                sx={{
                  fontWeight: "bold",
                  paddingRight: "5px",
                }}
              >
                {item.member.name}
              </Box>
              <Box component="span">added an evaluation for the </Box>
              <Box component="span"sx={{fontWeight: "bold", paddingRight: "5px"}}>Interview</Box>
              <Box component="span">stage </Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Box component="span">{item.body}</Box>
        <br />
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

function DisqualifiedActivity({ item, candidate }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingLeft: isExtraSmallScreen ? "0" : "55px",
          textAlign: "left",
          position: "relative",
        }}
      >
        {isExtraSmallScreen ? (
          ""
        ) : (
          <PanToolIcon
            sx={{ position: "absolute", left: 0, top: "4px" }}
            fontSize="large"
            color="primary"
          />
        )}
        <Grid container>
          <Grid item xs={2} sm={1}>
            <Avatar {...stringAvatar(item.member.name)} />
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Box component="span" sx={{ paddingRight: "5px" }}>
                Disqualified by
              </Box>
              <Box component="span" sx={{ fontWeight: "bold", paddingRight: "5px" }}>
                {item.member.name}
              </Box>
              <Box component="span" variant="subtitle2">
                · {timeAgo.format(new Date(item.created_at))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Divider />
      </Box>
    </Box>
  );
}

export default function ActivityItem({ item, candidate }) {
  if (kinds.includes(item.action) && item.member) {
    return <MemberKindActivity item={item} kind={item.action} />;
  } else if (item.action == "applied" && !item.member) {
    return <AppliedActivity item={item} candidate={candidate} />;
  } else if (item.action == "message" && item.member) {
    return <MemberMessageActivity item={item} candidate={candidate} />;
  } else if (item.action == "message") {
    return <CandidateMessageActivity item={item} candidate={candidate} />
  } else if (item.action == "copied" && item.member) {
    return <MemberResurfacedActivity item={item} candidate={candidate} />;
  } else if (item.action == "video-interview-requested" && item.member) {
    return <VideoInterviewRequestedActivity item={item} candidate={candidate} />;
  } else if (item.action == "video-interview-completed") {
    return <VideoInterviewCompletedActivity item={item} candidate={candidate} />;
  } else if (item.action == "assessment-requested" && item.member) {
    return <AssessmentRequestedActivity item={item} candidate={candidate} />;
  } else if (item.action == "assessment-completed") {
    return <AssessmentCompletedActivity item={item} candidate={candidate} />;
  } else if (item.action == "comment" && item.member) {
    return <CommentActivity item={item} candidate={candidate} />;
  } else if (item.action == "rating" && item.member) {
    return <RatingActivity item={item} candidate={candidate} />;
  } else if (item.action == "disqualified" && item.member) {
    return <DisqualifiedActivity item={item} candidate={candidate} />;
  } 
}
