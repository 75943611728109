import { Box } from "@mui/system";
import { Typography, Divider } from "@mui/material";

export default function Skills({ skills }) {
  return (
    <Box sx={{ marginTop: "10px", textAlign: "left" }}>
      <Typography sx={{ fontWeight: "bold" }}>Skills</Typography>
      <Divider />
      <Typography>{skills.map(item => item.name).join(", ")}</Typography>
    </Box>
  );
}
