import Questions from "./Questions";

export default function QuestionsWrapper({candidate}) {
    const getArray = (arr) => {
        if (arr && arr.length) {
          return arr;
        }
        return null;
      };

      const answers = getArray(candidate.answers);
      return(<div>{answers ? <Questions answers={answers} /> : ""}</div>)
}