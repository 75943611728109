import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

export default function LoadingContainer() {
  return (
    <Box sx={{ padding: "30px" }}>
      <Box sx={{ marginTop: "20px" }}>
        <Divider />
      </Box>
      <br />

      <Box>
        <Grid container>
          <Grid item xs={2} sm={1}>
            <Skeleton variant="circular" width={80} height={80} />
          </Grid>
          <Grid item xs={10} sm={11}>
            <Box
              sx={{
                paddingLeft: "25px",
                textAlign: "left",
                verticalAlign: "top",
              }}
            >
              <Skeleton
                sx={{ marginBottom: "5px" }}
                variant="rectangular"
                width={210}
                height={30}
              />
              <Skeleton
                sx={{ marginBottom: "5px" }}
                variant="rectangular"
                width={100}
                height={15}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: "40px", textAlign: "left" }}>
        <Skeleton variant="rectangular" width={100} height={15} />
        <Divider />
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Box
          sx={{
            paddingLeft: "55px",
            textAlign: "left",
            position: "relative",
          }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <br />
          <Divider />
        </Box>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Box
          sx={{
            paddingLeft: "55px",
            textAlign: "left",
            position: "relative",
          }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <br />
          <Divider />
        </Box>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Box
          sx={{
            paddingLeft: "55px",
            textAlign: "left",
            position: "relative",
          }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <br />
          <Divider />
        </Box>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Box
          sx={{
            paddingLeft: "55px",
            textAlign: "left",
            position: "relative",
          }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <br />
        </Box>
      </Box>

      <Box sx={{ marginTop: "40px", textAlign: "left" }}>
        <Skeleton variant="rectangular" width={100} height={15} />
        <Divider />
      </Box>

      <Skeleton />
      <Skeleton />
      <Skeleton />

      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Box>
  );
}
