import ErrorContainer from "./ErrorContainer";
import LoadingContainer from "./LoadingContainer";
import ViewContainer from "./ViewContainer";

export default function WrapperContainer(data) {
    if(data.error) {
        return (<ErrorContainer />);
    }
    if(data.loading) {
        return (<LoadingContainer />);
    }
    if(data.data.candidate) {
        return (<ViewContainer data={data.data} />);
    }
}