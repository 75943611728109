import { Typography, Divider, Box } from "@mui/material";


export default function CoverLetter({coverLetter}) {
  return (
    <Box sx={{ marginTop: "10px", textAlign: "left" }}>
      <Typography sx={{ fontWeight: "bold" }}>Cover Letter</Typography>
      <Divider />
      <Box dangerouslySetInnerHTML={{
            __html: `<p>${coverLetter.replace(/\n/g, "<br>")}</p>`,
          }}></Box>
    </Box>
  );
}
