import { useMediaQuery } from "@mui/material";

export default function Resume({ resume }) {
  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <iframe
      style={{ position: "relative", width: "100%", height: isExtraSmallScreen ? "150px" : "800px" }}
      src={resume}
    />
  );
}
