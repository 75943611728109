import { Box } from "@mui/system";
import { Divider, Typography, Grid } from "@mui/material";

import EducationItem from "./EducationItem";
export default function Education({ education }) {
  return (
    <Box sx={{ marginTop: "10px", textAlign: "left" }}>
      <Typography sx={{ fontWeight: "bold" }}>Education</Typography>
      <Divider />
      <Box>
        {education.map(item => <EducationItem item={item} />)}
      </Box>
    </Box>
  );
}
