import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "@mui/material";

import ActivityItem from "./ActivityItem";

export default function ActivityWrapper({ data }) {

  const reversedActivities = data.activities.reverse();

  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box sx={{ padding: isExtraSmallScreen ? "0 5px" : "0 30px", marginBottom: "30px" }}>
      <Box sx={{ marginTop: "40px", textAlign: "left" }}>
        <Typography sx={{ fontWeight: "bold" }}>Activity</Typography>
        <Divider />
      </Box>
      {reversedActivities.map((item, key) => (
        <ActivityItem item={item} candidate={data.candidate} />
      ))}
    </Box>
  );
}
