import { Box } from "@mui/system";
import { Divider, Typography, Grid } from "@mui/material";
import { Facebook, Twitter, GitHub, LinkedIn } from "@mui/icons-material";
import styled from 'styled-components'

const StyledDiv = styled.div`
    display: inline;
    margin: 0, 5px;
    a {
        color: black;
    }
    a:visited {
        color: black;
    }
`

const getLink = (social) => {
    if(social.type == "facebook") {
        return <StyledDiv><a href={social.url}><Facebook fontSize="large" /></a></StyledDiv>
    } else if(social.type == "twitter") {
        return <StyledDiv><a href={social.url}><Twitter fontSize="large" /></a></StyledDiv>
    } else if(social.type == "github") {
        return <StyledDiv><a href={social.url}><GitHub fontSize="large" /></a></StyledDiv>
    } else if(social.type == "linkedin") {
        return <StyledDiv><a href={social.url}><LinkedIn fontSize="large" /></a></StyledDiv>
    }
}


export default function SocialProfiles({ socialProfiles }) {
  return (
    <Box sx={{ marginTop: "10px", textAlign: "left" }}>
      <Typography sx={{ fontWeight: "bold" }}>Social Profiles</Typography>
      <Typography sx={{fontWeight: "light"}}>These profiles were automatically retrieved, not provided by the candidate.</Typography>
      <Box>
        {socialProfiles.map(item => getLink(item))}
      </Box>
    </Box>
  );
}
