import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box, Grid } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#8d8e8d",
      main: "#14a510",
      dark: "#3f8146",
      contrastText: "#fff",
    },
  },
});

export default function NotFound() {
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 233,
                    width: 350,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="The house from the offer."
                  src="../logo/white_logo_transparent_background.png"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h1">404</Typography>
                <br></br>
                <Typography variant="h6">
                  The page you're looking for doesn't exist.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    );
}