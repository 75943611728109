import { useState, useEffect } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/system/Box";
import { Divider } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";

function getQuestion(item) {
  if (item.answer && item.answer.hasOwnProperty("checked")) {
    return (
      <Box
        sx={{
          margin: "20px 0",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {item.answer.checked ? (
          <CheckCircle sx={{ marginRight: "10px", color: "#14a510" }} />
        ) : (
          <Cancel sx={{ marginRight: "10px", color: "red" }} />
        )}
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          {item.question.body}
        </Box>
        <Divider />
      </Box>
    );
  } else if (item.answer && item.answer.body) {
    return (
      <Box sx={{ margin: "20px 0" }}>
        <Typography sx={{ fontWeight: "bold" }}>
          {item.question.body}
        </Typography>
        <Typography>{item.answer.body}</Typography>
      </Box>
    );
  } else if (item.answer && item.answer.choices) {
    return (
      <Box sx={{ margin: "20px 0" }}>
        <Typography sx={{ fontWeight: "bold" }}>
          {item.question.body}
        </Typography>
        {item.answer.choices.map((singleAnswer) => { return <Typography>{singleAnswer.body}</Typography>})}
      </Box>
    );
  }
}

export default function Questions({ answers }) {
  const [expand, setExpand] = useState(true);

  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  const handleChange = () => {
    setExpand(!expand);
  };

  return (
    <div>
      <Accordion expanded={expand} onChange={handleChange}>
        <AccordionSummary
          sx={{ backgroundColor: "lightgray" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Questions</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ padding: isExtraSmallScreen ? "0 0px" : "0 15px" }}
        >
          <Box sx={{ margin: "20 0" }}>
            {answers.map((item) => getQuestion(item))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
