import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";



const calculateYear = (startDate, endDate, current) => {
    if(startDate && endDate) {
        return `${startDate.split('-')[0]} - ${endDate.split('-')[0]}`;
    } else if(startDate && current) {
        return `${startDate.split('-')[0]} - now`
    } else if(startDate) {
        return `${startDate.split('-')[0]} - n/a`
    } else {
        return 'n/a';
    }
}

export default function ExperienceItem({ item }) {


    

  return (
    <Grid container sx={{ margin: "15px 0" }}>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: "light" }}>{calculateYear(item.start_date, item.end_date, item.current)}</Typography>
      </Grid>
      <Grid item xs={10}>
          <Box component="span" sx={{ fontWeight: "bold", marginRight: "5px" }}>
           {item.title}
          </Box>
          at
          <Box component="span" sx={{ fontWeight: "bold", marginLeft: "5px" }}>{item.company}</Box>
      </Grid>
    </Grid>
  );
}
