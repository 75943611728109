import { useState, useEffect } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/system/Box";

import Summary from "./Summary";
import Experience from "./Experience";
import Education from "./Education";
import Skills from "./Skills";
import ContactDetails from "./ContactDetails";
import SocialProfiles from "./SocialProfiles";
import Resume from "./Resume";

import { useMediaQuery } from "@mui/material";
import CoverLetter from "./CoverLetter";

const getArray = (arr) => {
  if (arr && arr.length) {
    return arr;
  }
  return null;
};

export default function CandidateProfileWrapper({ candidate }) {
  const [expand, setExpand] = useState(true);

  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  const handleChange = () => {
    setExpand(!expand);
  };

  const summary = candidate.summary;
  const coverLetter = candidate.cover_letter;
  const experience = getArray(candidate.experience_entries);
  const education = getArray(candidate.education_entries);
  const skills = getArray(candidate.skills);
  const socialProfiles = getArray(candidate.social_profiles);
  const resume = candidate.resume_url


  return (
    <div>
      <Accordion expanded={expand} onChange={handleChange}>
        <AccordionSummary
          sx={{ backgroundColor: "lightgray" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Candidate Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: isExtraSmallScreen ? "0 0px" : "0 15px" }}>
            {coverLetter ? <CoverLetter coverLetter={coverLetter} /> : ""}
            {summary ? <Summary summary={candidate.summary} /> : ""}
            {experience ? <Experience experience={experience} /> : ""}
            {education ? <Education education={education} /> : ""}
            {resume ? <Resume resume={resume} /> : ""}
            {skills ? <Skills skills={skills} /> : ""}
            {socialProfiles ? <SocialProfiles socialProfiles={socialProfiles} /> : ""}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
