import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import PhoneIcon from "@mui/icons-material/Phone";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import EmailIcon from "@mui/icons-material/Email";
import { useMediaQuery } from "@mui/material";

import ActivityWrapper from "../Activity/ActivityWrapper";
import CandidateProfileWrapper from "../CandidateProfile/CandidateProfileWrapper";
import QuestionsWrapper from "../Questions/QuestionsWrapper";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')



function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export default function ViewContainer(data) {


  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box>
      {/* //BLANK SPACE HEADER START */}
      <Box sx={{ padding: "30px" }}>
        <Box sx={{ marginTop: "20px" }}>
          <Divider />
        </Box>
      </Box>

      {/* //BLANK SPACE HEADER STOP */}
      {/* //CANDIDATE NAME START */}
      <Box sx={{ padding: isExtraSmallScreen ? "0 10px" : "0 30px" }}>
        <Grid container>
          <Grid item xs={2} sm={1}>
            <img
              style={{ borderRadius: "50px" }}
              width="80"
              height="80"
              src={data.data.candidate.image_url ?? "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"}
            />
          </Grid>
          <Grid item xs={10} sm={11}>
            <Box
              sx={{
                paddingLeft: "25px",
                textAlign: "left",
                verticalAlign: "top",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {data.data.candidate.name}
              </Typography>
              <Typography>{data.data.candidate.headline}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* //CANDIDATE NAME STOP */}

      {/* ACTIVITY START */}

      <ActivityWrapper data={data.data} />
      
      {/* ACTIVITY STOP */}

      <CandidateProfileWrapper candidate={data.data.candidate}/>
      <QuestionsWrapper candidate={data.data.candidate} />

      <Box sx={{padding: "20px"}}>
        {timeAgo.format(new Date(data.data.candidate.created_at))}
      </Box>

    </Box>
  );
}
