import { Box } from "@mui/system";
import { Divider, Typography, Grid } from "@mui/material";

import ExperienceItem from "./ExperienceItem";

export default function Experience({ experience }) {
  return (
    <Box sx={{ marginTop: "10px", textAlign: "left" }}>
      <Typography sx={{ fontWeight: "bold" }}>Work Experience</Typography>
      <Divider />
      <Box>
        {experience.map(item => <ExperienceItem item={item} />)}
      </Box>
    </Box>
  );
}
