import { Typography, Divider, Box } from "@mui/material";


export default function Summary({summary}) {
  return (
    <Box sx={{ marginTop: "10px", textAlign: "left" }}>
      <Typography sx={{ fontWeight: "bold" }}>Summary</Typography>
      <Divider />
      <Box dangerouslySetInnerHTML={{
            __html: `<p>${summary.replace(/\n/g, "<br>")}</p>`,
          }}></Box>
    </Box>
  );
}
