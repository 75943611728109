import Box from "@mui/system/Box";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import Typography from "@mui/material/Typography";

export default function ErrorContainer() {
  return (
    <Box
      sx={{ padding: "30px", display: "flex", flexDirection: "column", justifyContent: "center" }}
    >
      <Box sx={{textAlign: "center"}}>
        <ScreenSearchDesktopIcon  sx={{width: "5em", height: "5em", color: "#14a510"}}/>
      </Box>
      <Box sx={{textAlign: "center"}}>
        <Typography variant="h4">
          Oops, something went wrong.
        </Typography>
        <Typography>
            Make sure you have copied the link with correct candidate id. If the problem persist, let us know so we can address the problem.
        </Typography>
      </Box>
    </Box>
  );
}
